<template>
  <base-section
    id="certification"
    space="36"
  >
    <v-container>
      <base-section-heading title="QUALITY MANAGEMENT SYSTEM CERTIFICATION AND STANDARD COMPLIANCES" />
      <v-row>
        <v-col
          v-for="n in 4"
          :key="n"
          cols="3"
        >
          <base-img
            :src="require(`@/assets/certification-${n}.png`)"
            color="grey"
            contain
            height="90"
            width="120"
            class="mx-auto"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCertification',
  }
</script>
